import { UsdtSVG, UsdcSVG } from '@/shared/assets/svg';
import SolanaIcon from '@/shared/assets/svg/HeaderPopover/SolonsLogo.svg';

export const getCurrentImage = (currency: 'USDC' | 'USDT' | 'SOL') => {
  switch (currency) {
    case 'USDT':
      return <UsdtSVG />;
    case 'USDC':
      return <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />;
    case 'SOL':
      return (
        <SolanaIcon style={{ background: '#000000', borderRadius: '50%' }} />
      );
    default:
      return <UsdcSVG fill='#3875C9' secondFill='#FFFFFF' />;
  }
};
